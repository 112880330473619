/* CartPage.css */

.cart-page {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0.5rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .back-button:hover {
    color: #0089B8;
  }
  
  .back-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  
  .page-title {
    font-size: 1.875rem;
    font-weight: 700;
    color: #0089B8;
  }
  
  .cart-layout {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .cart-layout {
      flex-direction: row;
    }
  
    .cart-items-section {
      width: 66.666667%;
    }
  
    .summary-section {
      width: 33.333333%;
    }
  }
  
  /* Empty Cart Styles */
  .empty-cart-card {
    background-color: white;
    border-radius: 0.75rem;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .empty-cart-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .empty-cart-icon {
    width: 3rem;
    height: 3rem;
    color: #9ca3af;
    margin-bottom: 1rem;
  }
  
  .empty-cart-message {
    font-size: 1.25rem;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .continue-shopping-button {
    background-color: #0089B8;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .continue-shopping-button:hover {
    background-color: #007aa6;
  }
  
  /* Cart Items Styles */
  .cart-items-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .cart-item {
    display: flex;
    gap: 1rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .item-image-container {
    position: relative;
    height: 6rem;
    width: 6rem;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .item-details {
    flex-grow: 1;
  }
  
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 0.5rem;
  }
  
  .item-name {
    font-weight: 700;
    font-size: 1.125rem;
    color: #1f2937;
  }
  
  .item-tag {
    font-size: 0.875rem;
    font-weight: 600;
    color: #0089B8;
    background-color: #ebf5ff;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }
  
  .item-price {
    color: #0089B8;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .quantity-button {
    height: 2rem;
    width: 2rem;
    border-radius: 9999px;
    border: 1px solid #d1d5db;
    background: none;
    color: #4b5563;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }
  
  .quantity-button:hover:not(:disabled) {
    background-color: #f3f4f6;
  }
  
  .quantity-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .quantity-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
  
  .quantity-display {
    width: 2rem;
    text-align: center;
    color: #4b5563;
  }
  
  .remove-button {
    color: #9ca3af;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    padding: 0.25rem;
  }
  
  .remove-button:hover {
    color: #ef4444;
  }
  
  .remove-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  /* Summary Section Styles */
  .summary-card {
    position: sticky;
    top: 2rem;
    background-color: white;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .summary-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0089B8;
    margin-bottom: 1rem;
  }
  
  .summary-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .summary-label {
    color: #6b7280;
  }
  
  .summary-value {
    font-weight: 500;
    color: #1f2937;
  }
  
  .summary-total {
    border-top: 1px solid #e5e7eb;
    padding-top: 1rem;
    margin-top: 1rem;
  }
  
  .total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .total-label {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .total-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0089B8;
  }
  
  .checkout-button {
    width: 100%;
    background-color: #0089B8;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-button:hover:not(:disabled) {
    background-color: #007aa6;
  }
  
  .checkout-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
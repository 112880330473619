@font-face {
    font-family: 'DINN';
    /* Choose a unique name for your font */
    src: url('../../../public/fonts/ArbFONTS-DINNextLTArabic-Regular-4.ttf') format('truetype');
    /* Adjust the path and format */
    font-weight: normal;
    font-style: normal;
}

.hero-page {
    background-color: #121B31;
    text-align: center;
    min-height: 100vh;
    color: #fff;
    font-family: 'Sofia Sans', sans-serif;
}

.hero-page a {
    text-decoration: none;
}

.bg-image {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}

.bg-image .logo-con {
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 120px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-cont {
    max-width: 550px;
}

.hero-cont h2 {
    color: #FAFAFA;
    text-align: center;
    font-size: 91px;
    font-style: normal;
    font-weight: 700;
    line-height: 82px;
    /* 31.648% */
}

.hero-cont h4 {
    color: #42B3DB;
    text-align: center;
    font-size: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
    /* 61.277% */

}

.hero-cont p {
    color: #FAFAFA;
    font-family: 'Segoe UI';
    text-align: center;
    font-size: 15.875px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.hero-cont .price-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212F54;
    text-align: center;
    height: 49px;
    padding: 10px;
    border-radius: 10px;
    background: #42B3DB;
    border: none;
    font-family: "DINN";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 260px;
}

.login-form p {
    color: #FFF;
    text-align: center;
    font-family: "DINN";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.login-form .input-group {
    border-radius: 10px;
    background: #F3FBFF;
    height: 45px;
    border: 1px solid #F3FBFF;
}

.login-form .input-group span {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 30px;
}

.login-form .forgit-pass {
    color: #FFF;
    text-align: center;
    font-family: "DINN";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.login-form .forgit-pass:hover {
    color: #42B3DB;
}

.login-form .input-group input {
    border-radius: 5px;
    background: transparent;
    border: none;
    height: 100%;
    padding: 10px;
    outline: none;
    box-shadow: none;
}

.login-form button {
    display: flex;
    width: 259px;
    height: 49px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #42B3DB;
    border: none;
    color: #212529;
    text-align: center;
    font-family: "DINN";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
}

.hero-cont .price-link:hover,
.login-form button:hover {
    color: #fff;
}

.login-form button.disabled,
.login-form button:disabled {
    opacity: .6;
    pointer-events: none;
    background-color: #42b2db52;
    color: #fff;
}

@media (max-width:576px) {
    .bg-image .logo-con {
        width: 100px;
        height: 100px;
    }

    .hero-cont h2 {
        font-size: 55px;
        line-height: 55px;
    }

    .hero-cont h4 {
        color: #42B3DB;
        text-align: center;
        font-size: 29px;
        font-style: normal;
        font-weight: 700;
        line-height: 28.8px;
    }
}
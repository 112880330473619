/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control:focus {
  box-shadow: none;
} */

/* .btn-primary {
  background-color: #222d58;
}
.btn-primary:hover {
  background-color: #0987b1;
} */


.hamada {
  width: 140px !important;
}

.fa-download, .fa-trash-alt, .fa-pencil-alt, .fa-globe, .fa-lock, .logsImg{
  position: relative;
}


.fa-download:hover:after, .fa-trash-alt:hover:after, .fa-pencil-alt:hover:after, .fa-globe:hover:after, .fa-lock:hover:after, .logsImg:hover:after{
  position: absolute;
  content:'';
  top: 150%;
  left: 50%;
  transform:translateX(-50%);
  z-index: 3;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /* sans-serif; */
  font-weight: 400;
  font-size: 12px;
}

.logsImg:hover:after{
  content:'Logs';
}

.fa-download:hover:after{
  content:'Download';
}

.fa-trash-alt:hover:after{
  content:'Delete';
}

.fa-pencil-alt:hover:after{
  content:'Edit';
}

.fa-globe:hover:after{
  content:'Public';
}

.fa-lock:hover:after{
  content:'Private';
}


.fa-lock.soft:hover:after{
  content:'Soft Lock';
  min-width: 70px
}

button.gm-ui-hover-effect{
  display: none!important;
}
.ReactModal__Overlay {
  z-index: 900;
} 
.pricing-container {
  min-height: 100vh;
  background-color: #121b31;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-content {
  width: 100%;
  max-width: 1200px;
}

.pricing-header {
  text-align: center;
  margin-bottom: 48px;
}

.pricing-header h2 {
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin-bottom: 16px;
}

.pricing-header p {
  color: #94a3b8;
}

.pricing-grid {
  display: grid;
  gap: 32px;
}

@media (min-width: 768px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pricing-card {
  background-color: rgba(11, 18, 33, 0.5);
  border: 1px solid #1a2942;
  border-radius: 8px;
  backdrop-filter: blur(8px);
  position: relative;
  overflow: hidden;
}

.pricing-card.popular {
  border: 2px solid #06b6d4;
}

.popular-badge {
  background-color: #06b6d4;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
  border-bottom-right-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.card-header {
  text-align: center;
  padding: 48px 24px 32px;
}

.card-title {
  font-size: 24px;
  font-weight: 900;
  color: white;
  /* margin-bottom: 8px; */
}

.price {
  font-size: 36px;
  font-weight: 900;
  color: #06b6d4;
  /* margin-bottom: 16px; */
}

.description {
  color: #94a3b8;
  margin-bottom: 0px;
}

.card-content {
  padding: 0 24px 24px;
}

.features-list {
  margin-bottom: 24px;
}

.features-list li {
  display: flex;
  align-items: center;
  color: #cbd5e1;
  margin-bottom: 12px;
}

.check-icon {
  width: 20px;
  height: 20px;
  color: #06b6d4;
  margin-right: 8px;
  flex-shrink: 0;
}

.cta-button {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 0px;
  font-weight: 800;
  font-size: 1.2rem;
  transition: all 0.2s;
  background-color: rgba(6, 182, 212, 0.1);
  color: #06b6d4;
}

.cta-button:hover {
  background-color: rgba(6, 182, 212, 0.2);
}

.cta-button.popular {
  background: linear-gradient(to right, #06b6d4, #3b82f6);
  color: white;
}

.cta-button.popular:hover {
  background: linear-gradient(to right, #0891b2, #2563eb);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

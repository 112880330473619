/* In your CSS file */
.ReactModal__Content {
    width: 95%;
    max-width: 800px;
    max-height: 90vh;
    overflow: auto;
  }
  
  @media (min-width: 576px) {
    .ReactModal__Content {
      width: 85%;
    }
  }
  
  @media (min-width: 768px) {
    .ReactModal__Content {
      width: 75%;
    }
  }
  
  @media (min-width: 992px) {
    .ReactModal__Content {
      width: 65%;
    }
  }
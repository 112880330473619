/* ProductDetails.css */

.product-details-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1.5rem;
}

.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0.5rem;
  color: #4b5563;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
}

.back-button:hover {
  color: #0089B8;
}

.back-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.product-layout {
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
  .product-layout {
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
}

/* Image Section */
.image-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.main-image:not(.zoomed):hover {
  transform: scale(1.05);
}

.main-image.zoomed {
  object-fit: contain;
  transform: scale(1.5);
}

.zoom-button,
.nav-button {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.5rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.zoom-button:hover,
.nav-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.zoom-button {
  top: 0.5rem;
  right: 0.5rem;
}

.nav-button {
  top: 50%;
  transform: translateY(-50%);
}

.nav-button-left {
  left: 0.5rem;
}

.nav-button-right {
  right: 0.5rem;
}

.control-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #1f2937;
}

.thumbnails-container {
  margin-top: 1.5rem;
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.thumbnails-scroll {
  display: flex;
  gap: 0.75rem;
  overflow-x: auto;
  padding: 0.5rem 1rem;
  scrollbar-width: thin;
  scrollbar-color: #9ca3af transparent;
}

.thumbnails-scroll::-webkit-scrollbar {
  height: 6px;
}

.thumbnails-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.thumbnails-scroll::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 3px;
}

.thumbnail-button {
  position: relative;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
}

.thumbnail-button:hover {
  opacity: 1;
}

.thumbnail-button.active {
  border: 2px solid #0089B8;
  transform: scale(1.1);
  opacity: 1;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Product Info Section */
.product-info {
  background-color: white;
  padding: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f4f6;
  height: fit-content;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.4s ease-out forwards;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-title {
  font-size: 2rem;
  font-weight: 800;
  color: #111827;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

@media (min-width: 640px) {
  .product-title {
    font-size: 2.25rem;
  }
}

.product-description {
  color: #4b5563;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.625;
}

@media (min-width: 640px) {
  .product-description {
    font-size: 1.125rem;
  }
}

.price-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.price {
  color: #0089B8;
  font-size: 2.5rem;
  font-weight: 700;
  margin-right: 0.5rem;
}

@media (min-width: 640px) {
  .price {
    font-size: 3rem;
  }
}

.currency {
  color: #6b7280;
  font-size: 1.125rem;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.quantity-button {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 9999px;
  border: 2px solid #0089B8;
  color: #0089B8;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.quantity-button:hover:not(:disabled) {
  background-color: #0089B8;
  color: white;
}

.quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-icon {
  width: 1rem;
  height: 1rem;
}

.quantity-display {
  font-size: 1.25rem;
  font-weight: 500;
  width: 3rem;
  text-align: center;
}

.quantity-limit {
  color: #0089B8;
  background-color: #EBFAFF;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  display: inline-block;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.add-to-cart-button,
.buy-now-button {
  width: 100%;
  height: 3rem;
  border-radius: 0.75rem;
  font-weight: 600;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 1rem;
}

@media (min-width: 640px) {
  .add-to-cart-button,
  .buy-now-button {
    font-size: 1.125rem;
  }
}

.add-to-cart-button {
  background-color: #0089B8;
  color: white;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.add-to-cart-button-disabled {
  width: 100%;
  height: 3rem;
  border-radius: 0.75rem;
  font-weight: 600;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 1rem;
  background-color: #6b7280;
  color: white;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.add-to-cart-button:hover {
  background-color: #007aa6;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.buy-now-button {
  background-color: white;
  color: #0089B8;
  border: 2px solid #0089B8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.buy-now-button:hover {
  background-color: #0089B8;
  color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}
/* ProductGridCard.css */
.grid-card-container {
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  
  .grid-card-container:hover {
    transform: scale(1.03);
  }
  
  .grid-card-container:active {
    transform: scale(0.98);
  }
  
  .grid-card {
    background-color: white;
    border: 1px solid #f3f4f6;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .grid-card:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
                0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
  }
  
  .image-container {
    position: relative;
    aspect-ratio: 1;
    overflow: hidden;
    cursor: pointer;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .grid-card:hover .product-image {
    transform: scale(1.05);
  }
  
  .badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: white;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
  }
  
  .badge-new {
    background-color: #10B981;
  }
  
  .badge-sale {
    background-color: #EF4444;
  }
  
  .badge-limited {
    background-color: #F59E0B;
  }
  
  .badge-default {
    background-color: #6B7280;
  }
  
  .details-container {
    padding: 1.5rem;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .product-name {
    font-size: 1.25rem;
    font-weight: 700;
    color: #111827;
  }
  
  .tag-count {
    font-size: 0.875rem;
    font-weight: 600;
    color: #0089B8;
    background-color: #EBF5FF;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }
  
  .description {
    color: #4B5563;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .price {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0089B8;
  }
  
  .add-cart-button {
    display: flex;
    align-items: center;
    background-color: #0089B8;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .add-cart-button-disabled {
    display: flex;
    align-items: center;
    background-color: #6B7280;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-cart-button:hover {
    background-color: #006d91;
  }
  
  .cart-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
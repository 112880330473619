.payment-container {
  min-height: 100vh;
  background-color: #0b1221;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.card {
  width: 100%;
  max-width: 28rem;
  background-color: rgba(11, 18, 33, 0.5);
  border: 1px solid #1a2942;
  border-radius: 0.5rem;
  backdrop-filter: blur(8px);
  position: relative;
  overflow: hidden;
}

.card-header {
  text-align: center;
  padding: 3rem 1.5rem 2rem;
}

.icon-container {
  margin: 0 auto;
  width: 4rem;
  height: 4rem;
  background: linear-gradient(to right, #22d3ee, #2563eb);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.icon-container svg {
  width: 2rem;
  height: 2rem;
  color: white;
}

.card-title {
  font-size: 1.875rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.5rem;
}

.card-subtitle {
  color: #94a3b8;
}

.card-content {
  padding: 1.5rem;
}

.transaction-details {
  background-color: rgba(11, 18, 33, 0.8);
  border-radius: 0.5rem;
  padding: 1rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #94a3b8;
  margin-bottom: 0.75rem;
}

.detail-row:last-child {
  margin-bottom: 0;
}

.detail-value {
  color: white;
  font-weight: 600;
}

/* Glow effect */
.glow-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(45deg, #22d3ee, #2563eb);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
}

/* Animation for check mark */
@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.icon-container {
  animation: scaleIn 0.5s ease-out forwards;
}

/* .navbar{
    width: 100%;
    height: 50px;
    background-color: aqua;
    position: sticky;
    top:0;
    display: flex;
    align-items: center;
}

.navLeft, .navRight{
 flex: 3;
 background-color: blue;
}

.navCenter{
    flex: 6;
} */

.container {
    margin-top: 0;
    width: 100%;
    margin: auto;
}

.justify-content-center {
    margin-top: 0%;
    width: 100%;
}

.logo {
    width: 91px;
    height: 30px;
}

.navbar {
    text-align: center;
}

hr {
    border: 1.7px solid #12495c;
    margin-top: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 20px;
}

.slider.round:before {
    border-radius: 50%;
}